import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Link, useHistory } from 'react-router-dom';

import { clearSurvey } from '../../functions/utils';
import { CLEAR_SURVEY, CLEAR_PDF_SELECTOR } from '../../types/surveyTypes';
import { CLEAR_FLOORPLANS } from '../../types/floorPlanTypes';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

export const DoorInspection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [savedCosts, setSavedCosts] = useState(null);
    const [costsErrors, setCostsErrors] = useState('');
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setSavedCosts(JSON.parse(localStorage.getItem('costs')));
    }, []);

    useEffect(() => {
        setLoading(true);
        
        if (savedCosts) {
            const { form_templates } = JSON.parse(localStorage.getItem('dependencies'));

            let keyList = [];
            
            const tmpKeyList = form_templates?.costs?.sections.filter((section) => {
                console.log(section);
                const { fields } = section;
                
    
                fields.map((field) => {
                    keyList.push(field?.name);
                });
            });

            if (keyList.length !== Object.values(savedCosts).length) {
                setCostsErrors('There is an error with the costs. Please go to the costs tab to rectify and re-save.');
            }
        }
        
        setLoading(false);
    }, [savedCosts]);

    const handleStartAgain = () => {
        dispatch({ type: CLEAR_SURVEY });
        dispatch({ type: CLEAR_FLOORPLANS });
        dispatch({ type: CLEAR_PDF_SELECTOR });

        clearSurvey();

        history.push('/survey/new');
    }

    const renderedContent = savedCosts ? (
        <Fragment>
            {!costsErrors ? (
                <Redirect to="/survey/new" />
            ) : (
                <Fragment>
                    <div>
                        <h2 style={{ textAlign: 'center' }}>Errors found in cost page</h2>
                        <div style={{ textAlign: 'center' }}>{costsErrors}</div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    ) : (
        <Fragment>
            <div>
                <h2 style={{ textAlign: 'center' }}>Before you start your first survey...</h2>
                <p style={{ textAlign: 'center' }}>Please specify the costs applicable for repairs</p>
                <div className="form__buttons">
                    <Link className="form__button" to="/costs">Set up repair costs</Link>
                </div>
            </div>
        </Fragment>
    )

    return (
        <Fragment>
            <Header>Door Survey Set Up</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                {!loading ? renderedContent : <div>Loading...</div>}
            </Content>
            <BottomNav />
        </Fragment>
    )
}
