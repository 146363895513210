import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import Localbase from 'localbase';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { createSurvey, updateSurvey } from '../../actions/surveyActions';

import { CLEAR_DOORLIST_PROCEED, CLEAR_SURVEY } from '../../types/surveyTypes';
import { CLEAR_FLOORPLANS } from '../../types/floorPlanTypes';

export const InspectionForm = ({ showEdit }) => {
    const [survey, setSurvey] = useState(null);
    const [clientName, setClientName] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [clientTelephone, setClientTelephone] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [surveyAddress, setSurveyAddress] = useState('');
    const [surveyBuilding, setSurveyBuilding] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactTelephone, setContactTelephone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [buildingPhoto, setBuildingPhoto] = useState('');
    const [floorPlan, setFloorPlan] = useState('');
    const [includeCosts, setIncludeCosts] = useState('yes');
    const [buildingPreview, setBuildingPreview] = useState('');
    const [comments, setComments] = useState('');
    const [errors, setErrors] = useState(null);

    const surveySelector = useSelector((state) => state.survey);
    const { activePlan, surveyLoading, doorListProceed, proceedToPDFSelector } = surveySelector;

    const authSelector = useSelector((state) => state.auth);
    const { user } = authSelector;
    
    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { plans } = floorPlanSelector;

    const dispatch = useDispatch();
    const history = useHistory();
    
    useEffect(() => {
        if (showEdit) {
            const surveyId = localStorage.getItem('activeSurvey');
            const clientData = JSON.parse(localStorage.getItem('clientData'));
    
            if (surveyId) {
                console.log('here 2');
                const existingSurvey = JSON.parse(localStorage.getItem('survey'));
                
                if (existingSurvey) {
                    if (existingSurvey[surveyId]) {
                        setSurvey(existingSurvey[surveyId]);
                    }
                }

                checkBuildingPhotos(surveyId, clientData);
                
                if (clientData[surveyId]) {
                    setClientName(clientData[surveyId]?.inspection_client_name);
                    setClientAddress(clientData[surveyId]?.inspection_client_address);
                    setClientTelephone(clientData[surveyId]?.inspection_client_telephone);
                    setClientEmail(clientData[surveyId]?.inspection_client_email);
                    setSurveyAddress(clientData[surveyId]?.inspection_survey_address);
                    setSurveyBuilding(clientData[surveyId]?.inspection_building_name);
                    setContactName(clientData[surveyId]?.inspection_contact_name);
                    setContactTelephone(clientData[surveyId]?.inspection_contact_telephone);
                    setContactEmail(clientData[surveyId]?.inspection_contact_email);
                    setIncludeCosts(clientData[surveyId]?.inspection_has_costs);
                    setComments(clientData[surveyId]?.inspection_comments);
                }
            }
        } else {
            dispatch({ type: CLEAR_SURVEY });
            dispatch({ type: CLEAR_FLOORPLANS });
        }
        
    }, []);

    async function checkBuildingPhotos (surveyId, clientData) {
        const db = new Localbase('saved-uploads');
            
        try {
            const document = await db.collection('building-photos').doc(surveyId).get();

            console.log(document);

            if (document) {
                setBuildingPreview(URL.createObjectURL(document));
                console.log('document here')
                return true;
            }

        } catch (error) {
            console.error(error);
        }

        setBuildingPreview(clientData[surveyId]?.inspection_building_photo ? clientData[surveyId]?.inspection_building_photo : '');

        return false;
    }

    const handleBuildingUpload = (e) => {
        const { files } = e.target;

        setBuildingPhoto(files[0]);
        setBuildingPreview(URL.createObjectURL(files[0]));
    };

    const handleFloorPlanUpload = (e) => {
        const { files } = e.target;

        setFloorPlan(files[0]);
    };

    const onSubmit = event => {
        event.preventDefault();
        setErrors(null);

        let errs = {};
        const { recordId } = survey || 0;

        if (!clientName) {
            const err = { inspection_client_name: { message: 'Please provide a client name' }};

            Object.assign(errs, err);
        }

        if (!clientAddress) {
            const err = { inspection_client_address: { message: 'Please provide a client address' }};

            Object.assign(errs, err);
        }

        if (!clientTelephone) {
            const err = { inspection_client_telephone: { message: 'Please provide a client telephone number' }};

            Object.assign(errs, err);
        }

        if (!clientEmail) {
            const err = { inspection_client_email: { message: 'Please provide a client email address' }};

            Object.assign(errs, err);
        }

        if (!surveyAddress) {
            const err = { inspection_survey_address: { message: 'Please provide a survey address' }};

            Object.assign(errs, err);
        }

        if (!surveyBuilding) {
            const err = { inspection_building_name: { message: 'Please provide a building name' }};

            Object.assign(errs, err);
        }

        if (!contactName) {
            const err = { inspection_contact_name: { message: 'Please provide a contact name' }};

            Object.assign(errs, err);
        }

        if (!contactTelephone) {
            const err = { inspection_contact_telephone: { message: 'Please provide a contact telephone number' }};

            Object.assign(errs, err);
        }

        if (clientName && clientAddress && clientTelephone && clientEmail && surveyAddress && surveyBuilding && contactName && contactTelephone) {

            if (showEdit) {
                dispatch(updateSurvey(parseInt(user?.customer_id), clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorPlan, comments, includeCosts, recordId ));
            } else {
                dispatch(createSurvey(parseInt(user?.customer_id), clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorPlan, comments, includeCosts ));
            }
        } else {
            setErrors(errs);
        }
    }

    useEffect(() => {
        if (doorListProceed) {
            history.push('/survey/doors');

            dispatch({ type: CLEAR_DOORLIST_PROCEED });
        }
    }, [doorListProceed]);
    
    useEffect(() => {
        if (proceedToPDFSelector) {
            history.push('/survey/floorplan');
        }
    }, [proceedToPDFSelector]);
    
    return  (
        <Fragment>
            <Header>Door Survey - Client Details</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <span className="login__welcome">Please complete all fields</span>
                <form className="login__form" onSubmit={onSubmit}>
                    <div className="form-group">
                        <label className="form__label" htmlFor="clientName">Client Name</label>
                        <input className="form__input" type="text" id="clientName" name="clientName" value={clientName} autoComplete={false} onChange={(e) => setClientName(e.target.value)} />
                        <div className="error">{errors?.inspection_client_name?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="clientAddress">Address</label>
                        <input className="form__input" type="text" id="clientAddress" name="clientAddress" value={clientAddress} autoComplete={false} onChange={(e) => setClientAddress(e.target.value)} />
                        <div className="error">{errors?.inspection_client_address?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="clientTelephone">Telephone Number</label>
                        <input className="form__input" type="text" id="clientTelephone" name="clientTelephone" value={clientTelephone} autoComplete={false} onChange={(e) => setClientTelephone(e.target.value)} />
                        <div className="error">{errors?.inspection_client_telephone?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="clientEmail">Email Address</label>
                        <input className="form__input" type="text" id="clientEmail" name="clientEmail" value={clientEmail} autoComplete={false} onChange={(e) => setClientEmail(e.target.value)} />
                        <div className="error">{errors?.inspection_client_email?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="surveyAddress">Survey Address</label>
                        <input className="form__input" type="text" id="surveyAddress" name="surveyAddress" value={surveyAddress} autoComplete={false} onChange={(e) => setSurveyAddress(e.target.value)} />
                        <div className="error">{errors?.inspection_survey_address?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="surveyBuilding">Name of Building</label>
                        <input className="form__input" type="text" id="surveyBuilding" name="surveyBuilding" value={surveyBuilding} autoComplete={false} onChange={(e) => setSurveyBuilding(e.target.value)} />
                        <div className="error">{errors?.inspection_building_name?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="buildingPhoto">Building Photo</label>
                        <input className="form__input" type="file" id="buildingPhoto" name="buildingPhoto" onChange={(e) => handleBuildingUpload(e)} />
                        {buildingPreview && (<div className="thumbnail"><img src={buildingPreview} /></div>)}
                        <div className="error">{errors?.inspection_building_photo?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="contactName">Contact Name</label>
                        <input className="form__input" type="text" id="contactName" name="contactName" value={contactName} autoComplete={false} onChange={(e) => setContactName(e.target.value)} />
                        <div className="error">{errors?.inspection_contact_name?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="contactTelephone">Contact Telephone Number</label>
                        <input className="form__input" type="text" id="contactTelephone" name="contactTelephone" value={contactTelephone} autoComplete={false} onChange={(e) => setContactTelephone(e.target.value)} />
                        <div className="error">{errors?.inspection_contact_telephone?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="contactEmail">Contact Email Address</label>
                        <input className="form__input" type="text" id="contactEmail" name="contactEmail" value={contactEmail} autoComplete={false} onChange={(e) => setContactEmail(e.target.value)} />
                        <div className="error">{errors?.inspection_contact_email?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="comments">Comments</label>
                        <input className="form__input" type="text" id="comments" name="comments" value={comments} autoComplete={false} onChange={(e) => setComments(e.target.value)} />
                        <div className="error">{errors?.inspection_comments?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="floorPlan">Floor Plan <span style={{ fontSize: '0.875rem' }}>(You must be online to upload a PDF)</span></label>
                        <input className="form__input" type="file" id="floorPlan" name="floorPlan" onChange={(e) => handleFloorPlanUpload(e)} />
                        {plans && (Object.keys(plans).length > 1 ? (
                            <div style={{ marginTop: '1rem' }}>Multiple Floor Plans</div>
                        ) : (
                            <div style={{ marginTop: '1rem' }}>{plans[activePlan]?.label}</div>
                        ))}
                        <div className="error">{errors?.inspection_floorplan_photo?.message}</div>
                    </div>
                    <div className="form-group">
                        <label className="form__label" htmlFor="includeCosts">Include Costs</label>
                        <select className="form__input" id="includeCosts" name="includeCosts" value={includeCosts} onChange={(e) => setIncludeCosts(e.target.value)}>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <div className="error">{errors?.inspection_has_costs}</div>
                    </div>
                    <div className="form__buttons">
                        {showEdit ? (
                            <Fragment>
                                <button aria-label="Back" className="form__button secondary form__button--inline" type="button" onClick={() => history.push('/survey/doors')} style={{ marginRight: '1rem' }}>Back</button>
                                <button aria-label="Update & Continue" disabled={surveyLoading} className="form__button form__button--inline" type="submit">Update & Continue <PulseLoader loading={surveyLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                            </Fragment>
                        ) : (
                            <button aria-label="Save & Continue" disabled={surveyLoading} className="form__button form__button--inline" type="submit">Save & Continue <PulseLoader loading={surveyLoading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                        )}
                    </div>
                </form>
            </Content>
            <BottomNav />
        </Fragment>
    )
}