import { combineReducers } from 'redux';

import authReducer from './authReducer';
import appReducer from './appReducer';
import profileReducer from './profileReducer';
import surveyReducer from './surveyReducer';
import costsReducer from './costsReducer';
import dashboardReducer from './dashboardReducer';
import floorPlanReducer from './floorPlanReducer';

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    profile: profileReducer,
    survey: surveyReducer,
    costs: costsReducer,
    dashboard: dashboardReducer,
    floorPlan: floorPlanReducer,
});