import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import { getDependencies } from '../../actions/appActions';
import { getRecentSurveys, removeSurvey } from '../../actions/dashboardActions';
import { getCosts, setCosts } from '../../actions/costsActions';
import { checkSubscription } from '../../actions/authActions';
import { loadSurvey } from '../../actions/surveyActions';
import { loadFloorPlan } from '../../actions/floorPlanActions';
import { clearSurvey } from '../../functions/utils';

import { ReactComponent as Report } from '../../assets/report.svg';
import { ReactComponent as Play } from '../../assets/play.svg';

import './Dashboard.css';
import { CLEAR_SURVEY } from '../../types/surveyTypes';

const Dashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dashboardSelector = useSelector((state) => state.dashboard);
    const authSelector = useSelector((state) => state.auth);
    const { recentSurveys, localSurveys, loading } = dashboardSelector;
    const { user } = authSelector;
    const [savedSurveys, setSavedSurveys] = useState(null);
    const [confirmation, setConfirmation] = useState(false);
    const [removeKey, setRemoveKey] = useState('');

    const handleContinueSurvey = (surveyId) => {
        dispatch(loadSurvey(surveyId));
        dispatch(loadFloorPlan(surveyId));

        history.push('/survey/doors');
    }

    const handleRemoveSurvey = (key) => {
        setConfirmation(true);
        setRemoveKey(key);
    }

    const handleRejectRemoveSurvey = () => {
        setConfirmation(false);
        setRemoveKey('');
    }

    const handleConfirmRemoveSurvey = () => {
        dispatch(removeSurvey(removeKey));
        setConfirmation(false);
        setRemoveKey('');
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        const tmp = JSON.parse(localStorage.getItem('survey'));

        setSavedSurveys(tmp);

        const costs = JSON.parse(localStorage.getItem('costs'));

        dispatch(checkSubscription());
        dispatch(getDependencies());
        dispatch(getRecentSurveys());
        dispatch({ type: CLEAR_SURVEY });

        if (costs) {
            dispatch(setCosts(costs));
        } else {
            dispatch(getCosts());
        }
            
    }, [])

    return (
        <Fragment>
            <Header>Door Survey App</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <p style={{ textAlign: 'center' }}><button className="form__button" onClick={() => history.push('/survey')}>Click to start a New Survey</button></p>
                <h2 style={{ textAlign: 'center' }}>Recent surveys</h2>
                <div>
                    {loading ? 
                        <div>Loading...</div> : recentSurveys ? (
                            <React.Fragment>
                                <div className="recent_surveys">
                                    <div>Client</div>
                                    <div>Building</div>
                                    <div className="desktop">Doors</div>
                                    <div className="flex justify-center">Status</div>
                                    <div className="desktop">Cost</div>
                                    <div className="flex justify-center">Report</div>
                                </div>
                                {recentSurveys.map((survey) => {
                                    let selectedSecretKey = '';

                                    if (savedSurveys) {
                                        selectedSecretKey = Object.keys(savedSurveys).find((key) => savedSurveys[key].surveyKey === survey?.survey_secret_key);
                                    }

                                    return (
                                        <div className="recent_surveys entry">
                                            <div>{survey?.inspection_client_name}</div>
                                            <div>{survey?.inspection_building_name}</div>
                                            <div className="desktop">{survey?.inspection_doors}</div>
                                            <div className="flex justify-center">{survey?.survey_status}</div>
                                            <div className="desktop">{survey?.inspection_total ? (<span>£{survey?.inspection_total}</span>) : (<span>-</span>)}</div>
                                            <div className="flex justify-center">
                                                {survey?.survey_status === 'Completed' && (
                                                    <Fragment>
                                                        <a target="_blank" href={`https://www.webglu21.co.uk/survey/?${survey?.survey_secret_key}&${user?.secret_code}`}><Report className="download" style={{ width: '24px', height: '24px' }} /></a>
                                                        <button className="remove-survey" onClick={() => handleRemoveSurvey(survey?.survey_secret_key)}><svg className="w-6 h-6 download" fill="" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg></button>
                                                    </Fragment>
                                                )}
                                                {survey?.survey_status === 'In Progress' && selectedSecretKey && (
                                                    <Fragment>
                                                        <button className="continue-survey" onClick={() => handleContinueSurvey(selectedSecretKey)}><Play className="continue" style={{ width: '24px', height: '24px', marginRight: '1rem' }} /></button>
                                                        <button className="remove-survey" onClick={() => handleRemoveSurvey(survey?.survey_secret_key)}><svg className="w-6 h-6 download" fill="" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg></button>
                                                    </Fragment>
                                                )}
                                                {survey?.survey_status === 'In Progress' && !selectedSecretKey && (
                                                    <button className="remove-survey" onClick={() => handleRemoveSurvey(survey?.survey_secret_key)}><svg className="w-6 h-6 download" fill="" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg></button>
                                                )}
                                                {survey?.survey_status === 'Expired' && (
                                                    <button className="remove-survey" onClick={() => handleRemoveSurvey(survey?.survey_secret_key)}><svg className="w-6 h-6 download" fill="" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg></button>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div style={{ padding: '1rem', textAlign: 'center' }}>Offline Surveys</div>
                                {localSurveys.length > 0 ? (
                                    <p style={{ marginTop: '1rem', textAlign: 'center' }}>These surveys were created when you were offline. Please review and resubmit the survey</p>
                                ) : (
                                    <p style={{ marginTop: '1rem', textAlign: 'center' }}>No offline surveys</p>
                                )}
                                {localSurveys.map((survey) => {
                                    return (
                                        <div className="recent_surveys entry">
                                            <div>{survey?.inspection_client_name}</div>
                                            <div>{survey?.inspection_building_name}</div>
                                            <div className="desktop">{survey?.inspection_doors}</div>
                                            <div className="flex justify-center">{survey?.survey_status}</div>
                                            <div className="desktop">{survey?.inspection_total ? (<span>£{survey?.inspection_total}</span>) : (<span>-</span>)}</div>
                                            <div className="flex justify-center">
                                                {survey?.survey_status === 'In Progress' && survey?.surveyId && (
                                                    <button className="continue-survey" onClick={() => handleContinueSurvey(survey?.surveyId)}><Play className="continue" style={{ width: '24px', height: '24px' }} /></button>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                                <p style={{ textAlign: 'center' }}>All surveys can be accessed from your members account area at http://www.napfis.co.uk/. You will also find a management interface to make additional adjustments.</p>
                            </ React.Fragment>
                        ) : <div style={{ textAlign: 'center' }}>You have no recent surveys....</div>   
                    }
                </div>
                {confirmation && removeKey && (
                    <div class="popup">
                        <p className="title">Are you sure you want to delete all application data?</p>
                        <div className="form__buttons">
                            <button onClick={handleRejectRemoveSurvey} className="form__button secondary">No</button>
                            <button onClick={handleConfirmRemoveSurvey} className="form__button primary">Yes</button>
                        </div>
                    </div>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default Dashboard;
