import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { FloorPlan } from '../FloorPlan';

import { removeDoor, goToPDFSelector, setActivePlan, addDoor } from '../../actions/surveyActions';
import { getDependencies } from '../../actions/appActions';
import { removePin } from '../../actions/floorPlanActions';

import './styles/DoorList.css';

export const DoorList = () => {
    const { search } = useLocation();
    const [doorArray, setDoorArray] = useState([]);
    const [totalDoors, setTotalDoors] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [showError, setShowError] = useState(false);
    const [showFloorPlan, setShowFloorPlan] = useState(false);
    const [floorPlanLabel, setFloorPlanLabel] = useState('');
    const [plansArray, setPlansArray] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

    const surveySelector = useSelector((state) => state.survey);
    const { id: surveyId, doors, totalFields, doorListLoading, activePlan, activeNoPlan, proceedToPDFSelector, floorPlans } = surveySelector;

    const floorPlanSelector = useSelector((state) => state.floorPlan);
    const { plans, errors } = floorPlanSelector;

    const handleManageFloorPlans = () => {
        dispatch(goToPDFSelector());
    }

    const handleRemoveDoor = (doorId, id) => {
        dispatch(removeDoor(doorId, id));
        
        const tmpDoor = totalDoors.find((door) => door.id === id);

        if (tmpDoor) {
            dispatch(removePin(tmpDoor.pinId));
        }
    }

    const handleProceedToSummary = () => {
        if (disabled) {
            setShowError(true);
        } else {
            setShowError(false);
            history.push('/survey/summary');
        }
    }

    const handleDoorChange = (e) => {
        dispatch(setActivePlan(e.target.value));
    }

    useEffect(() => {
        if (proceedToPDFSelector) {
            history.push('/survey/floorplan');
        }
    }, [proceedToPDFSelector]);
    
    useEffect(() => {
        if (activePlan) {
            if (plans) {
                if (plans[activePlan]) {
                    setFloorPlanLabel(plans[activePlan]?.label);
                }
            }
        }
    }, [activePlan]);

    useEffect(() => {
        if (plans) {
            let tmpPlansArray = [];

            for (const [key, value] of Object.entries(plans)) {
                if (value.active) {
                    tmpPlansArray.push(value);
                }
            }
            setPlansArray(tmpPlansArray);
        }
    }, [plans]);

    useEffect(() => {
        if (doors) {
            const tmpDoors = Object.values(doors);
            let filteredDoors = [];

            if (activeNoPlan) {
                filteredDoors = tmpDoors;
            } else {
                filteredDoors = tmpDoors.filter((door) => door.planRef === activePlan);
            }
    
            setDoorArray(filteredDoors);
            setTotalDoors(tmpDoors);
        }
    },[doors, activePlan])

    useEffect(() => {
        const surveyId = localStorage.getItem('activeSurvey');
        const survey = JSON.parse(localStorage.getItem('survey'));

        if (doorArray.length > 0) {
            if (surveyId) {
                const localDoors = survey[surveyId]?.doors;
        
                if (localDoors) {
                    const localDoorsArray = Object.values(localDoors);
        
                    if (localDoorsArray.length !== totalDoors.length) {
                        setDisabled(true);
                    } else {
                        totalDoors.some((door) => {
                            if (door.completedFields !== totalFields) {
                                setDisabled(true);
        
                                return true;
                            }
        
                            setDisabled(false);
                        });
                    }
                }
            }
        } else {
            setDisabled(true);
        }

    }, [doorArray, totalFields]);

    useEffect(() => {
        dispatch(getDependencies());
    }, []);

    return (
        <Fragment>
            <Header>Door Surveys</Header>
            <Menu />
            <Content style={{ marginTop: '100.27px' }} >
                <div className="doorList">
                    <p style={{ textAlign: 'center' }}>Browse through each door and complete a series of checks to determine if the doors are compliant/meet regulations. Once you've completed each door, click process survey.</p>
                    {errors && (<div className="error" style={{ textAlign: 'center' }}>{errors}</div>)}
                    <div className="form__buttons" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            {plansArray.length > 1 && (
                                <select style={{ marginRight: '1rem' }} onChange={handleDoorChange} value={activePlan}>
                                    <option value="">Please select a floor plan</option>
                                    {plansArray.map((plan) => {
                                        return <option value={plan?.id}>{plan?.label}</option>
                                    })}
                                </select>
                            )}
                            {plansArray.length < 2 && floorPlanLabel}
                        </div>
                        <div>
                            {activePlan && Object.keys(floorPlans).length > 1 && (
                                <button className="form__button secondary addDoor" style={{ marginRight: '1rem' }} onClick={handleManageFloorPlans}>Manage Floor Plans</button>
                            )}
                            {(activePlan) && (
                                <button className="form__button secondary addDoor" onClick={() => setShowFloorPlan(!showFloorPlan)}>Manage Doors</button>
                            )}
                            {(activeNoPlan) && (
                                <button className="form__button secondary addDoor" onClick={() => dispatch(addDoor(surveyId))}>Add Door</button>
                            )}
                        </div>
                    </div>
                    {activePlan || activeNoPlan ? !doorListLoading ? doorArray.length > 0 ? doorArray.map((door) => {
                        let errorClass = '';

                        errorClass = door?.completedFields === totalFields ? 'field-completed' : 'field-incompleted';

                        const surveyId = localStorage.getItem('activeSurvey');
                        const survey = JSON.parse(localStorage.getItem('survey'));
                        let ref = null;

                        if (surveyId) {
                            const tmpDoor = survey[surveyId]?.doors;
    
                            if (tmpDoor) {
                                if (tmpDoor[door.id]) {
                                    ref = tmpDoor[door.id].reference;
                                }
                            }
    
                            if (!ref) {
                                ref = door?.id;
                            }
                        }
                
                        return (
                            <div key={door.id} className={`doorList__container ${errorClass} ${showError && 'show'}`}>
                                <div className="doorList__content">
                                    <span>{ref}</span>
                                    {door?.completedFields === totalFields && (
                                        <span className="door-complete">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
                                        </span>
                                    )}
                                </div>
                                <button className="edit" onClick={() => history.push(`/survey/doors/${door?.id}`)} style={{ marginRight: '1rem' }}>Edit</button>
                                <button className="remove" onClick={() => handleRemoveDoor(door?.doorId, door?.id)}>Remove</button>
                            </div>
                        );
                    }) : (
                        <Fragment>
                            {(activePlan ? (
                                <div style={{ textAlign: 'center', marginTop: '1rem' }}>No doors set for this floor plan<br />Click manage doors to start adding to the plan</div>
                            ) : (
                                <div style={{ textAlign: 'center', marginTop: '1rem' }}>No doors set. Start adding doors by clicking the 'Add Door' button.</div>
                            ))}
                        </Fragment>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>Loading...</div>
                    ) : (
                        <div style={{ textAlign: 'center', marginTop: '1rem' }}>{plansArray.length > 0 && (<>Please select a floor plan to start</>)}</div>
                    )}
                    {(showError || search.includes('error')) && (<div style={{ textAlign: 'center', marginTop: '1rem' }}>You have an incomplete door inspection. Please rectify and save your doors to continue.</div>)}
                    <div className="form__buttons">
                        <button style={{ marginRight: '1rem' }} className="form__button secondary form__button--inline" onClick={() => history.push('/survey/edit')}>Edit Client Form</button>
                        <button className="form__button form__button--inline" onClick={handleProceedToSummary}>Process Survey</button>
                    </div>
                    {showFloorPlan && (
                        <FloorPlan toggle={showFloorPlan} setToggle={setShowFloorPlan} />
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    )
}
