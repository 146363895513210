import axios from 'axios';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import Localbase from 'localbase';
import _, { floor } from 'lodash';

import { saveFloorPlan, setPDFImage, clearFloorPlans } from './floorPlanActions';

import { LOAD_SURVEY_REQUEST, LOAD_SURVEY_SUCCESS, LOAD_SURVEY_FAILURE, CREATE_SURVEY_REQUEST, CREATE_SURVEY_SUCCESS, CREATE_SURVEY_FAILURE, SAVE_DOOR_REQUEST, SAVE_DOOR_SUCCESS, SAVE_DOOR_FAILURE, LOAD_DOOR_REQUEST, LOAD_DOOR_SUCCESS, LOAD_DOOR_FAILURE, ADD_DOOR_REQUEST, ADD_DOOR_SUCCESS, ADD_DOOR_FAILURE, REMOVE_DOOR_REQUEST, REMOVE_DOOR_SUCCESS, REMOVE_DOOR_FAILURE, UPDATE_SURVEY_REQUEST, UPDATE_SURVEY_SUCCESS, UPDATE_SURVEY_FAILURE, CLEAR_DOOR_PROCEED, ASSIGN_PINS_REQUEST, ASSIGN_PINS_SUCCESS, ASSIGN_PINS_FAILURE, SET_ACTIVE_PLAN_REQUEST, SET_ACTIVE_PLAN_SUCCESS, SET_ACTIVE_PLAN_FAILURE, SAVE_DOOR_REF_REQUEST, SAVE_DOOR_REF_SUCCESS, SAVE_DOOR_REF_FAILURE, REMOVE_ALL_DOORS_REQUEST, REMOVE_ALL_DOORS_SUCCESS, REMOVE_ALL_DOORS_FAILURE, SET_PDF_SELECTOR_TRUE } from '../types/surveyTypes';
import { CLEAR_FLOORPLANS, SAVE_FLOORPLAN_SUCCESS } from '../types/floorPlanTypes';

export const loadSurvey = (surveyId) => dispatch => {
    try {
        if (!surveyId) {
            throw Error('No survey key');
        }

        dispatch({ type: LOAD_SURVEY_REQUEST });

        const savedSurvey = JSON.parse(localStorage.getItem('survey'));
        const savedActivePlan = JSON.parse(localStorage.getItem('activePlan'));
        const savedUploadedPlans = JSON.parse(localStorage.getItem('uploadedPlans'));
        const savedFloorPlans = JSON.parse(localStorage.getItem('floorPlans'));

        if (savedSurvey && savedSurvey[surveyId]) {
            let activePlan = null;
            let floorPlans = [];
            let uploadedPlans = [];

            if (savedActivePlan && savedActivePlan[surveyId]) {
                activePlan = savedActivePlan[surveyId];
            }

            if (savedUploadedPlans && savedUploadedPlans[surveyId]) {
                uploadedPlans = savedUploadedPlans[surveyId] === null ? uploadedPlans : savedUploadedPlans[surveyId];
            }

            if (savedFloorPlans) {
                if (savedFloorPlans[surveyId]) {
                    const tmpSavedFloorPlans = Object.keys(savedFloorPlans[surveyId]).filter((key) => savedFloorPlans[surveyId][key].active === true);

                    floorPlans = [...tmpSavedFloorPlans];
                }
            }

            dispatch({ type: LOAD_SURVEY_SUCCESS, payload: { ...savedSurvey[surveyId], activePlan: activePlan, floorPlans: uploadedPlans }});

            localStorage.setItem('activeSurvey', surveyId);
        } else {
            throw Error('Survey not found');
        }
    } catch (error) {
        console.log(error);
        console.log(error?.message);
        dispatch({ type: LOAD_SURVEY_FAILURE });
    }
}

export const createSurvey = (userId, clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorplan, comments, includeCosts) => async dispatch => {
    const formPayload = { inspection_client_name: clientName, inspection_client_address: clientAddress, inspection_client_telephone: clientTelephone, inspection_client_email: clientEmail, inspection_survey_address: surveyAddress, inspection_building_name: surveyBuilding, inspection_contact_name: contactName, inspection_contact_telephone: contactTelephone, inspection_contact_email: contactEmail, inspection_building_photo: buildingPhoto, inspection_floorplan_photo: floorplan, inspection_comments: comments, inspection_has_costs: includeCosts };
    
    const fd = new FormData();

    fd.append('inspection_client_name', clientName);
    fd.append('inspection_client_address', clientAddress);
    fd.append('inspection_client_telephone', clientTelephone);
    fd.append('inspection_client_email', clientEmail);
    fd.append('inspection_survey_address', surveyAddress);
    fd.append('inspection_building_name', surveyBuilding);
    fd.append('inspection_contact_name', contactName);
    fd.append('inspection_contact_telephone', contactTelephone);
    fd.append('inspection_contact_email', contactEmail);
    fd.append('inspection_has_costs', includeCosts);

    if (typeof buildingPhoto === 'object') {
        fd.append('inspection_building_photo', buildingPhoto, buildingPhoto?.name);
    } else {
        fd.append('inspection_building_photo', '');
    }
    if (typeof floorplan === 'object') {
        fd.append('inspection_floorplan_photo', floorplan, floorplan?.name);
    } else {
        fd.append('inspection_floorplan_photo', '');
    }

    fd.append('inspection_comments', comments);

    const surveyId = uuidv4();

    let survey = JSON.parse(localStorage.getItem('survey'));

    let costsActive = JSON.parse(localStorage.getItem('costsActive'));

    if (costsActive) {
        costsActive[surveyId] = includeCosts;
        localStorage.setItem('costsActive', JSON.stringify(costsActive));
    } else {
        localStorage.setItem('costsActive', JSON.stringify({ [surveyId]: includeCosts }));
    }

    try {
        dispatch({ type: CLEAR_FLOORPLANS });
        dispatch({ type: CREATE_SURVEY_REQUEST });

        const { data } = await axios.post('/app_handler.php', fd, { headers: { 'WG-Method': 'SAVE_CLIENTDATA', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: CREATE_SURVEY_SUCCESS, payload: { id: surveyId, userId, recordId: data?.record_id, doors: {}, surveyKey: data?.survey_key, floorPlans: data?.floor_plan, proceedToPDFSelector: data?.pdf_page_selector }});
        
        if (data?.save_uploaded_file) {
            dispatch({ type: SAVE_FLOORPLAN_SUCCESS, payload: { id: data?.floor_plan[0].id, label: 'Uploaded Floor Plan' } });
        }

        if (data?.download_pdf || data?.save_uploaded_file) {
            try {
                const response = await axios.get('/app_handler.php', {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Wg-Image': data?.download_pdf,
                        'Wg-ImageId': data?.floor_plan[0].id,
                        'Wg-Method': 'GET_PDF_PAGE',
                        'Wg-Key': Cookie.get('accessToken')
                    }
                });
                setPDFImage(response.data?.image, response.data?.id);
            } catch (error) {
                console.error(error);
            }
        }

        let activeNoPlan = false;

        if (data?.floor_plan) {
            if (data?.floor_plan.length === 1) {
                let activePlan = JSON.parse(localStorage.getItem('activePlan'));

                if (activePlan) {
                    if (activePlan[surveyId]) {
                        activePlan[surveyId] = data?.floor_plan[0].id;
                    } else {
                        const tmpActivePlan = {
                            [surveyId]: data?.floor_plan[0].id,
                        }
        
                        activePlan = Object.assign(activePlan, tmpActivePlan);
                    }

                    localStorage.setItem('activePlan', JSON.stringify(activePlan));

                } else {
                    localStorage.setItem('activePlan', JSON.stringify({ [surveyId]: data?.floor_plan[0].id }));
                }
                
                dispatch(saveFloorPlan(data?.floor_plan[0].id, 'Uploaded Floor Plan', surveyId));
            }
        } else {
            activeNoPlan = true;
        }

        const newSurveyObject = { id: surveyId, userId, recordId: data?.record_id, doors: {}, type: 'online', surveyKey: data?.survey_key, activeNoPlan };

        if (survey) {
            survey[surveyId] = newSurveyObject;

            localStorage.setItem('survey', JSON.stringify(survey));
        } else {
            localStorage.setItem('survey', JSON.stringify({ [surveyId] : newSurveyObject }));
        }

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = data?.fields;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem('clientData', JSON.stringify({ [surveyId]: data?.fields }));
        }

        let uploadedPlans = JSON.parse(localStorage.getItem('uploadedPlans'));

        if (uploadedPlans) {
            uploadedPlans[surveyId] = data?.floor_plan;
            localStorage.setItem('uploadedPlans', JSON.stringify(uploadedPlans));
        } else {
            localStorage.setItem('uploadedPlans', JSON.stringify({ [surveyId]: data?.floor_plan }));
        }

    } catch (error) {
        const floorPlanId = uuidv4();
        
        const data = { id: surveyId, userId, doors: {}, type: 'offline', floorPlans: floorplan && floorplan?.type !== 'application/pdf' ? [{ id: floorPlanId, image: '' }] : null, data: formPayload, activeNoPlan: floorplan ? false : true };
                
        dispatch({ type: CREATE_SURVEY_FAILURE, payload: data });

        let label = 'Uploaded Floor Plan';

        if (floorplan) {
            let errors = null;
            if (floorplan?.type === 'application/pdf') {
                errors = 'You cannot upload a PDF while offline.';
            } else {
                dispatch({ type: SAVE_FLOORPLAN_SUCCESS, payload: { id: floorPlanId, label, errors } });
            }
        }
        
        let uploadedPlans = JSON.parse(localStorage.getItem('uploadedPlans'));
        
        if (uploadedPlans) {
            uploadedPlans[surveyId] = [];
            if (floorplan && floorplan?.type !== 'application/pdf') {
                uploadedPlans[surveyId].push({ id: floorPlanId, label });
            } else {
                uploadedPlans[surveyId] = null;
            }
            localStorage.setItem('uploadedPlans', JSON.stringify(uploadedPlans));
        } else {
            let tmpFloorPlan = [];
            if (floorplan && floorplan?.type !== 'application/pdf') {
                tmpFloorPlan.push({ id: floorPlanId, label });
            } else {
                tmpFloorPlan = null;
            }
            localStorage.setItem('uploadedPlans', JSON.stringify({ [surveyId]: tmpFloorPlan }));
        }

        let activePlan = JSON.parse(localStorage.getItem('activePlan'));

        if (floorplan && floorplan?.type !== 'application/pdf') {
            if (activePlan) {
                if (activePlan[surveyId]) {
                    activePlan[surveyId] = floorPlanId;
                } else {
                    const tmpActivePlan = {
                        [surveyId]: floorPlanId,
                    }

                    activePlan = Object.assign(activePlan, tmpActivePlan);
                }

                localStorage.setItem('activePlan', JSON.stringify(activePlan));
    
            } else {
                localStorage.setItem('activePlan', JSON.stringify({ [surveyId]: floorPlanId }));
            }
        }

        if (survey) {
            survey[surveyId] = data;
            localStorage.setItem('survey', JSON.stringify(survey));
        } else {
            localStorage.setItem('survey', JSON.stringify({ [surveyId]: data }));
        }

        const db = new Localbase('saved-uploads');

        try {
            await db.collection('building-photos').add(buildingPhoto, surveyId);
            if (floorplan && floorplan?.type !== 'application/pdf')
            {
                await db.collection('pdf-photos').add(floorplan, floorPlanId);
            }
        } catch (error) {
            console.log(error);
        }

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = formPayload;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem('clientData', JSON.stringify({ [surveyId]: formPayload }));
        }
    }

    localStorage.setItem('activeSurvey', surveyId);
};

export const updateSurvey = (userId, clientName, clientAddress, clientTelephone, clientEmail, surveyAddress, surveyBuilding, contactName, contactTelephone, contactEmail, buildingPhoto, floorplan, comments, includeCosts, recordId) => async dispatch => {
    const formPayload = { inspection_client_name: clientName, inspection_client_address: clientAddress, inspection_client_telephone: clientTelephone, inspection_client_email: clientEmail, inspection_survey_address: surveyAddress, inspection_building_name: surveyBuilding, inspection_contact_name: contactName, inspection_contact_telephone: contactTelephone, inspection_contact_email: contactEmail, inspection_building_photo: buildingPhoto, inspection_floorplan_photo: floorplan, inspection_comments: comments };

    const fd = new FormData();

    fd.append('inspection_client_name', clientName);
    fd.append('inspection_client_address', clientAddress);
    fd.append('inspection_client_telephone', clientTelephone);
    fd.append('inspection_client_email', clientEmail);
    fd.append('inspection_survey_address', surveyAddress);
    fd.append('inspection_building_name', surveyBuilding);
    fd.append('inspection_contact_name', contactName);
    fd.append('inspection_contact_telephone', contactTelephone);
    fd.append('inspection_contact_email', contactEmail);
    fd.append('inspection_has_costs', includeCosts);

    if (typeof buildingPhoto === 'object') {
        fd.append('inspection_building_photo', buildingPhoto, buildingPhoto?.name);
    } else {
        fd.append('inspection_building_photo', '');
    }
    if (typeof floorplan === 'object') {
        fd.append('inspection_floorplan_photo', floorplan, floorplan?.name);
    } else {
        fd.append('inspection_floorplan_photo', '');
    }
    
    fd.append('inspection_comments', comments);
    
    const surveyId = localStorage.getItem('activeSurvey');

    let costsActive = JSON.parse(localStorage.getItem('costsActive'));

    if (costsActive) {
        costsActive[surveyId] = includeCosts;
        localStorage.setItem('costsActive', JSON.stringify(costsActive));
    } else {
        localStorage.setItem('costsActive', JSON.stringify({ [surveyId]: includeCosts }));
    }

    try {
        dispatch({ type: UPDATE_SURVEY_REQUEST });

        const { data } = await axios.post('/app_handler.php', fd, { headers: { 'WG-Method': 'SAVE_CLIENTDATA', 'WG-Key': Cookie.get('accessToken'), 'WG-RecordId': recordId }});

        dispatch({ type: UPDATE_SURVEY_SUCCESS, payload: { recordId: data?.record_id, surveyKey: data?.survey_key, floorPlans: data?.floor_plan, proceedToPDFSelector: data?.pdf_page_selector }});

        if (data?.save_uploaded_file) {
            dispatch({ type: SAVE_FLOORPLAN_SUCCESS, payload: { id: data?.floor_plan[0].id, label: 'Uploaded Floor Plan' } });
        }

        if (data?.download_pdf || data?.save_uploaded_file) {
            try {
                const response = await axios.get('/app_handler.php', {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Wg-Image': data?.download_pdf,
                        'Wg-ImageId': data?.floor_plan[0].id,
                        'Wg-Method': 'GET_PDF_PAGE',
                        'Wg-Key': Cookie.get('accessToken')
                    }
                });

                setPDFImage(response.data?.image, response.data?.id);
            } catch (error) {
                console.error(error);
            }
        }
        
        let activeNoPlan = false;
        let noActivePlanChanged = false;
        let survey = JSON.parse(localStorage.getItem('survey'));

        if (floorplan) {
            noActivePlanChanged = true;
            if (data?.floor_plan) {
                dispatch(clearFloorPlans());
                if (data?.floor_plan.length === 1) {
                    let activePlan = JSON.parse(localStorage.getItem('activePlan'));
    
                    if (activePlan) {
                        if (activePlan[surveyId]) {
                            activePlan[surveyId] = data?.floor_plan[0].id;
                        } else {
                            const tmpActivePlan = {
                                [surveyId]: data?.floor_plan[0].id,
                            }
            
                            activePlan = Object.assign(activePlan, tmpActivePlan);
                        }
    
                        localStorage.setItem('activePlan', JSON.stringify(activePlan));
    
                    } else {
                        localStorage.setItem('activePlan', JSON.stringify({ [surveyId]: data?.floor_plan[0].id }));
                    }
                    
                    dispatch(saveFloorPlan(data?.floor_plan[0].id, 'Uploaded Floor Plan', surveyId));
                }
            } else {
                activeNoPlan = true;
            }
        }

        if (survey[surveyId]) {
            survey[surveyId].recordId = data?.record_id;
            survey[surveyId].surveyKey = data?.survey_key;
            if (noActivePlanChanged) {
                survey[surveyId].activeNoPlan = activeNoPlan;
            }
            
            if (floorplan) {
                dispatch(removeAllDoors(recordId));
                survey[surveyId].doors = {};
            }
        }

        localStorage.setItem('survey', JSON.stringify(survey));

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = data?.fields;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem('clientData', JSON.stringify({ [surveyId]: data?.fields }));
        }

        if (floorplan) {
            let uploadedPlans = JSON.parse(localStorage.getItem('uploadedPlans'));
    
            if (uploadedPlans) {
                uploadedPlans[surveyId] = data?.floor_plan;
                localStorage.setItem('uploadedPlans', JSON.stringify(uploadedPlans));
            } else {
                localStorage.setItem('uploadedPlans', JSON.stringify({ [surveyId]: data?.floor_plan }));
            }
        }


    } catch (error) {
        const floorPlanId = uuidv4();
        
        const surveyId = localStorage.getItem('activeSurvey');

        console.log(floorplan);
        
        if (floorplan) {
            dispatch(clearFloorPlans());
            dispatch({ type: SAVE_FLOORPLAN_SUCCESS, payload: { id: floorPlanId, label: '' } });
            dispatch({ type: UPDATE_SURVEY_FAILURE, payload: { doors: {}, type: 'offline', floorPlans: floorplan ? [{ id: floorPlanId, image: '' }] : null, data: formPayload, activePlan: floorPlanId, activeNoPlan: false } });

            let uploadedPlans = JSON.parse(localStorage.getItem('uploadedPlans'));
    
            if (uploadedPlans) {
                uploadedPlans[surveyId] = [];
                if (floorplan) {
                    uploadedPlans[surveyId].push({ id: floorPlanId, label: '' });
                } else {
                    uploadedPlans[surveyId] = null;
                }
                localStorage.setItem('uploadedPlans', JSON.stringify(uploadedPlans));
            } else {
                let tmpFloorPlan = [];
                if (floorplan) {
                    tmpFloorPlan.push({ id: floorPlanId, label: '' });
                } else {
                    tmpFloorPlan = null;
                }
                localStorage.setItem('uploadedPlans', JSON.stringify({ [surveyId]: tmpFloorPlan }));
            }

            let activePlan = JSON.parse(localStorage.getItem('activePlan'));

            if (activePlan) {
                if (activePlan[surveyId]) {
                    activePlan[surveyId] = floorPlanId;
                } else {
                    const tmpActivePlan = {
                        [surveyId]: floorPlanId,
                    }

                    activePlan = Object.assign(activePlan, tmpActivePlan);
                }

                localStorage.setItem('activePlan', JSON.stringify(activePlan));

            } else {
                localStorage.setItem('activePlan', JSON.stringify({ [surveyId]: floorPlanId }));
            }
        } else {
            dispatch({ type: UPDATE_SURVEY_FAILURE, payload: { type: 'offline', data: formPayload } });
        }

        const db = new Localbase('saved-uploads');

        try {
            await db.collection('building-photos').add(buildingPhoto, surveyId);
            await db.collection('pdf-photos').add(floorplan, floorPlanId);
        } catch (error) {
            console.log(error);
        }

        let survey = JSON.parse(localStorage.getItem('survey'));

        if (survey[surveyId]) {
            survey[surveyId].activeNoPlan = floorplan ? false : true;
            
            if (floorplan) {
                survey[surveyId].doors = {};
            }
        }

        localStorage.setItem('survey', JSON.stringify(survey));

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = formPayload;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem('clientData', JSON.stringify({ [surveyId]: formPayload }));
        }
    }
}

export const saveDoor = (formData, id, surveyId, costs, scores, planRef, planLabel) => async dispatch => {
    let total = 0.00;
    let completedFields = 0;
    let doorId = 0;
    let savedFormData = formData;
    let doorToSave = {};

    console.log(scores);

    const db = new Localbase('saved-uploads');

    const activeSurvey = localStorage.getItem('activeSurvey');

    try {
        dispatch({ type: SAVE_DOOR_REQUEST });

        const fd = new FormData();

        for (const [key, value] of Object.entries(formData)) {
            if (value.type === 'file') {
                if (typeof value.value === 'object') {
                    fd.append(key, value.value, value.value.name);
                    
                    db.collection(`door-images-${activeSurvey}`).add({ key, file: value.value }, `${id}_${key}`);
                } else {
                    fd.append(key, value.value);
                }
            } else {
                fd.append(key, value.value);
            }
        }

        for (const [key, value] of Object.entries(costs)) {
            for (const [formKey, formValue] of Object.entries(formData)) {
                if (formValue.type !== 'file') {
                    if (key === formKey && (parseFloat(scores[key]) === 0.5 || parseFloat(scores[key]) === 0.0)) {
                        fd.append(`costs_${key}`, value);
                        total += parseFloat(value);
                    }

                    if (key === formKey && (parseFloat(scores[key]) === 0.5 || parseFloat(scores[key]) === 0.0 || parseFloat(scores[key]) === 1.0)) {
                        if (formValue?.required) {
                            completedFields++;
                        }
                    }
                }
            }
        }

        if (planLabel) {
            fd.append('door_floorplan_ref', planLabel);
        }

        let doorCosts = JSON.parse(localStorage.getItem('doorCosts'));

        if (doorCosts) {
            if (doorCosts[activeSurvey]) {
                if (doorCosts[activeSurvey][id]) {
                    doorCosts[activeSurvey][id] = costs;
                    localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
                } else {
                    doorCosts[activeSurvey] = { ...doorCosts[activeSurvey], [id]: costs };
                    localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
                }
            } else {
                doorCosts = { [activeSurvey]: { [id]: costs }};
                localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
            }
        } else {
            const newDoorCosts = { [id]: costs };

            localStorage.setItem('doorCosts', JSON.stringify({ [activeSurvey]: newDoorCosts }));
        }

        let doorScores = JSON.parse(localStorage.getItem('doorScores'));
        
        if (doorScores) {
            if (doorScores[activeSurvey]) {
                if (doorScores[activeSurvey][id]) {
                    doorScores[activeSurvey][id] = scores;
                    localStorage.setItem('doorScores', JSON.stringify(doorScores));
                } else {
                    doorScores[activeSurvey] = { ...doorScores[activeSurvey], [id]: scores };
                    localStorage.setItem('doorScores', JSON.stringify(doorScores));
                }
            } else {
                doorScores = { [activeSurvey]: { [id]: scores }};
                localStorage.setItem('doorScores', JSON.stringify(doorScores));
            }
        } else {
            const newDoorScores = { [id]: scores };
            localStorage.setItem('doorScores', JSON.stringify({ [activeSurvey]: newDoorScores }));
        }

        const survey = JSON.parse(localStorage.getItem('survey'));

        let doors = {};
        if (survey[activeSurvey]) {
            doors = survey[activeSurvey]?.doors;
        }
    
        const { data } = await axios.post('/app_handler.php', fd, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'WG-EntryId': doors[id]?.doorId || id,
                'WG-RecordId': surveyId,
                'Wg-Method': 'SAVE_DOOR',
                'Wg-Key': Cookie.get('accessToken')
            }
        });

        doorId = data?.form_entry_id;
        surveyId = data?.survey_id;

        if (data?.fields) {
            const db = new Localbase('saved-uploads');
            
            for (const [key, value] of Object.entries(formData)) {
                if (data?.fields[key]) {
                    savedFormData[key] = { type: value.type, value: data?.fields[key] };

                    try {

                        const document = await db.collection(`door-images-${activeSurvey}`).doc(`${id}_${key}`).get();

                        if (document) {
                            await db.collection(`door-images-${activeSurvey}`).doc(`${id}_${key}`).delete();
                        }

                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }

        doorToSave = { id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };

        dispatch({ type: SAVE_DOOR_SUCCESS, payload: { door: doorToSave, recordId: surveyId }});

    } catch (error) {
        doorToSave = { id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };

        dispatch({ type: SAVE_DOOR_FAILURE, payload: { door: doorToSave, recordId: surveyId }});
    }

    let survey = _.cloneDeep(JSON.parse(localStorage.getItem('survey')));
    
    if (survey[activeSurvey]) {

        survey[activeSurvey].recordId = surveyId;

        localStorage.setItem('survey', JSON.stringify(survey));
        
        // let door = survey[activeSurvey]?.doors;
    
        // if (door) {
        //     door[id] = { ...door[id], id, doorId, data: savedFormData, total, ref: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };
    
        //     localStorage.setItem('doors', JSON.stringify({ [activeSurvey]: door }));
        // } else {
        //     const newDoor = { [id]: { id, doorId, data: savedFormData, total, ref: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores } };
    
        //     localStorage.setItem('doors', JSON.stringify({ [activeSurvey]: newDoor }));
        // }

        survey[activeSurvey].recordId = surveyId;
    
        if (survey[activeSurvey].doors[id]) {
            survey[activeSurvey].doors[id] = { ...survey[activeSurvey].doors[id], id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };
        }
    
        localStorage.setItem('survey', JSON.stringify(survey));
    }

};

export const loadDoor = (id) => async dispatch => {
    try {
        dispatch({ type: LOAD_DOOR_REQUEST });

        const { data } = await axios.get('/app_handler.php', { headers: { 'WG-Method': 'GET_DOOR_TEMPLATE', 'Wg-EntryId': id, 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: LOAD_DOOR_SUCCESS, payload: data });
    } catch (error) {
        const dependencies = JSON.parse(localStorage.getItem('dependencies'));
        
        dispatch({ type: LOAD_DOOR_FAILURE, payload: dependencies?.form_templates?.door });
    }
};

export const addDoor = (surveyId, planRef, ref, pinId) => async dispatch => {
    const id = uuidv4();

    const data = { 
        id,
        total: 0.00,
        planRef: planRef,
        reference: ref || '',
        pinId: pinId,
    }

    try {
        dispatch({ type: ADD_DOOR_REQUEST });

        if (surveyId) {
            await axios.post('/app_handler.php', { data }, { headers: { 'WG-Method': 'ADD_DOOR', 'Wg-SurveyId': surveyId, 'WG-Key': Cookie.get('accessToken') }});
        } else {
            throw 'Survey ID not found';
        }

        dispatch({ type: ADD_DOOR_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADD_DOOR_FAILURE, payload: data });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    const activeSurvey = localStorage.getItem('activeSurvey');

    if (survey[activeSurvey]) {
        let doors = survey[activeSurvey]?.doors;
        
        if (doors) {
            if (doors[id]) {
                doors[id] = data;
            } else {
                doors = Object.assign(doors, { [id]: data });
            }
        } else {
            doors = { [id]: data };
        }

        survey[activeSurvey].doors[id] = data;
        if (survey[activeSurvey].doors[id]) {
            survey[activeSurvey].doors[id] = data;
        } else {
            survey[activeSurvey].doors = Object.assign(survey[activeSurvey].doors, { [id]: data });
        }
    }

    let clientData = JSON.parse(localStorage.getItem('clientData'));

    if (clientData[activeSurvey]) {
        clientData[activeSurvey].inspection_doors = clientData?.inspection_doors + 1;
    }

    localStorage.setItem('survey', JSON.stringify(survey));
    localStorage.setItem('clientData', JSON.stringify(clientData));
};

export const removeDoor = (doorId, id) => async dispatch => {
    // Door ID is what is returned by the server
    try {
        dispatch({ type: REMOVE_DOOR_REQUEST });

        if (doorId) {
            await axios.post('/app_handler.php', { door_id: doorId }, { headers: { 'WG-Method': 'REMOVE_DOOR', 'Wg-DoorId': doorId, 'WG-Key': Cookie.get('accessToken') }});
        }

        dispatch({ type: REMOVE_DOOR_SUCCESS, payload: id });
    } catch (error) {
        dispatch({ type: REMOVE_DOOR_FAILURE, payload: error.message });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    const activeSurvey = localStorage.getItem('activeSurvey');

    delete survey[activeSurvey].doors[id];

    localStorage.setItem('survey', JSON.stringify(survey));

    // let doors = survey?.doors;

    // delete doors[id];

    // localStorage.setItem('doors', JSON.stringify(doors));

    let clientData = JSON.parse(localStorage.getItem('clientData'));

    if (clientData[activeSurvey]) {
        clientData[activeSurvey].inspection_doors = clientData?.inspection_doors - 1;
    }

    localStorage.setItem('clientData', JSON.stringify(clientData));
};

export const removeAllDoors = (recordId) => async dispatch => {
    try {
        dispatch({ type: REMOVE_ALL_DOORS_REQUEST });

        await axios.get('/app_handler.php', { headers: { 'WG-Method': 'REMOVE_ALL_DOORS', 'Wg-RecordId': recordId, 'WG-Key': Cookie.get('accessToken') }});
        
        dispatch({ type: REMOVE_ALL_DOORS_SUCCESS });
    } catch (error) {
        dispatch({ type: REMOVE_ALL_DOORS_FAILURE, payload: error.message });
    }
};

export const clearDoorProceed = () => async dispatch => {
    dispatch({ type: CLEAR_DOOR_PROCEED });
};

export const assignPinsToDoors = (pins) => dispatch => {
    try {
        dispatch({ type: ASSIGN_PINS_REQUEST });

        dispatch({ type: ASSIGN_PINS_SUCCESS, payload: pins });
    } catch (error) {
        dispatch({ type: ASSIGN_PINS_FAILURE, payload: error?.response.data.errors });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    const activeSurvey = localStorage.getItem('activeSurvey');

    if (survey[activeSurvey]) {
        if (survey[activeSurvey]?.doors) {
            let index = 0;
    
            for (const [key, value] of Object.entries(survey[activeSurvey]?.doors)) {
                value.reference = pins[index]?.reference;
                value.pinId = pins[index]?.id;
                index++;
            }
        }
    }

    localStorage.setItem('survey', JSON.stringify(survey));
}

export const setActivePlan = (id) => dispatch => {
    try {
        dispatch({ type: SET_ACTIVE_PLAN_REQUEST });
        dispatch({ type: SET_ACTIVE_PLAN_SUCCESS, payload: id });

        const activeSurvey = localStorage.getItem('activeSurvey');
        let activePlan = JSON.parse(localStorage.getItem('activePlan'));
        
        if (activePlan) {
            if (activePlan[activeSurvey]) {
                activePlan[activeSurvey] = id;
            } else {
                const tmpActivePlan = {
                    [activeSurvey]: id
                }
                
                activePlan = Object.assign(activePlan, tmpActivePlan);

                // activePlan = Object.assign(activePlan, { [activeSurvey]: id });
            }
            
            localStorage.setItem('activePlan', JSON.stringify(activePlan));
        } else {
            localStorage.setItem('activePlan', JSON.stringify({ [activeSurvey]: id }));
        }

    } catch (error) {
        console.log(error);
        dispatch({ type: SET_ACTIVE_PLAN_FAILURE });
    }
}

export const saveDoorRef = (id, ref) => dispatch => {
    console.log('id ', id);
    try {
        dispatch({ type: SAVE_DOOR_REF_REQUEST });
        dispatch({ type: SAVE_DOOR_REF_SUCCESS, payload: { id, ref } });
    } catch (error) {
        dispatch({ type: SAVE_DOOR_REF_FAILURE });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    const activeSurvey = localStorage.getItem('activeSurvey');

    console.log(survey);
    console.log(activeSurvey);

    if (survey[activeSurvey]) {
        console.log('here 3')
        console.log(survey[activeSurvey])
        if (survey[activeSurvey]?.doors) {
            console.log('here 4')
            if (survey[activeSurvey]?.doors[id]) {
                survey[activeSurvey].doors[id].reference = ref;

                if (survey[activeSurvey].doors[id]?.data) {
                    console.log('here 1');
                    if (survey[activeSurvey].doors[id]?.data['door-reference_5f7c9de1088a0_formid_436']) {
                        console.log('here 2');
                        console.log(survey[activeSurvey].doors[id]?.data);
                        console.log(ref);
                        survey[activeSurvey].doors[id].data['door-reference_5f7c9de1088a0_formid_436'].value = ref;
                    }
                }
            }
        }
    }

    localStorage.setItem('survey', JSON.stringify(survey));
}

export const goToPDFSelector = () => dispatch => {
    dispatch({ type: SET_PDF_SELECTOR_TRUE });
}