import React, { useState } from 'react';

const Input = ({ field: { type, name, placeholder, label, id, symbol, repair_label }, value: defaultValue, handleChange }) => {
    const [value, setValue] = useState(defaultValue || '');

    console.log(value);

    const handleLocalChange = (e) => {
        handleChange(e);
        setValue(e.target.value);
    }

    return (
        <div>
            <label className="form__label" htmlFor={id}>{label}</label>
            <div style={{ marginTop: repair_label ? '1rem' : '0' }}>
                {repair_label && (<span className="repair_label">{repair_label}</span>)}
                <div>
                    {symbol && (<span className="symbol">{symbol}</span>)}<input className="form__input" id={id} type={type} name={name} placeholder={placeholder} value={value} onChange={handleLocalChange} />
                </div>
            </div>
        </div>
    )
}

export default Input;
